<template>
  <svg width="15px" height="14px">
    <path fill-rule="evenodd" :fill="fillColor"
          d="M11.289,13.468 L3.581,13.468 C2.062,13.468 0.827,12.233 0.827,10.714 L0.827,9.063 C0.827,8.759 1.073,8.512 1.378,8.512 C1.682,8.512 1.929,8.759 1.929,9.063 L1.929,10.714 C1.929,11.625 2.670,12.366 3.581,12.366 L11.289,12.366 C12.200,12.366 12.942,11.625 12.942,10.714 L12.942,9.063 C12.942,8.759 13.188,8.512 13.492,8.512 C13.797,8.512 14.043,8.759 14.043,9.063 L14.043,10.714 C14.043,12.233 12.808,13.468 11.289,13.468 ZM7.876,9.393 C7.868,9.402 7.857,9.406 7.850,9.414 C7.813,9.457 7.770,9.490 7.723,9.521 C7.703,9.533 7.687,9.549 7.666,9.559 C7.595,9.592 7.518,9.613 7.435,9.613 C7.352,9.613 7.275,9.592 7.204,9.559 C7.183,9.549 7.167,9.533 7.148,9.521 C7.100,9.490 7.057,9.457 7.020,9.414 C7.013,9.406 7.002,9.402 6.994,9.393 L5.343,7.190 C5.218,7.023 5.198,6.800 5.290,6.614 C5.384,6.427 5.574,6.309 5.783,6.309 L6.884,6.309 L6.884,0.803 C6.884,0.499 7.131,0.252 7.435,0.252 C7.739,0.252 7.986,0.499 7.986,0.803 L7.986,6.309 L9.087,6.309 C9.296,6.309 9.486,6.427 9.580,6.614 C9.673,6.800 9.653,7.023 9.527,7.190 L7.876,9.393 Z"/>
  </svg>
</template>
<script>
export default {
  props:['fillColor']
}
</script>

